import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import { type FC, memo } from "react"

import type { LoadingProps } from "./Loading.model"
import styles from "./Loading.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Loading")

export const Loading: FC<LoadingProps> = memo(({ className, size = "sm" }) => {
  const theme = useTheme()
  return (
    <Stack
      className={bem(undefined, undefined, className)}
      direction="row"
      spacing={2}
    >
      <svg height={0} width={0}>
        <defs>
          <linearGradient
            id="loading-gradient"
            x1="0%"
            x2="0%"
            y1="0%"
            y2="100%"
          >
            <stop offset="0%" stopColor={theme.palette.primary[500]} />
            <stop offset="50%" stopColor={theme.palette.grey[300]} />
            <stop offset="100%" stopColor={theme.palette.secondary[500]} />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{ "svg circle": { stroke: "url(#loading-gradient)" } }}
        className={bem("animation", {
          [`size-${size}`]: true,
        })}
      />
    </Stack>
  )
})

Loading.displayName = "Loading"
